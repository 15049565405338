import React from "react";
// import ChatIconSvg from "../../../assets/img/svgs/ChatIconSvg";
// import WhatsappIconSvg from "../../../assets/img/svgs/WhatsappIconSvg";
import { useTranslation } from "react-i18next";
import ModalCloseButton from "../components/ModalCloseButton";
import { Modal } from "react-bootstrap";
import ChatIconSvg from "../../../assets/svgs/ChatIconSvg";
import WhatsappIconSvg from "../../../assets/svgs/WhatsappIconSvg";

interface ISelectOtpMethod {
  onBackDropClick: () => void;
  onSelectHandler: (type: "SMS" | "Whatsapp") => void;
  phoneNumber: string;
  show: boolean;
}
const SelectOtpMethod: React.FC<ISelectOtpMethod> = (
  props: ISelectOtpMethod
) => {
  const { onBackDropClick, onSelectHandler, phoneNumber, show } = props;
  const { t } = useTranslation();
  // Prevent click events from propagating to the backdrop
  const handleModalContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  return (
    <Modal
      animation={false}
      show={show}
      className="product-quickview-modal-wrapper"
    >
      <ModalCloseButton onPressCross={onBackDropClick} />
      <div
        onClick={onBackDropClick}
        id="pop_send-otp-method"
        className="pop_up_maps_main pop_main_bg pop_main_menu_bg"
      >
        <div
          className="popup_delivery popup_from_bottom popup_slide"
          onClick={handleModalContentClick}
        >
          <br />
          <div className="container ml-20 mr-20">
            <h3 style={{ marginBottom: 30 }}>{t("send_verification_code")}</h3>
            <div
              onClick={() => onSelectHandler("SMS")}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex align-items-center">
                <ChatIconSvg />
                <span className="otp-method-txt"> {t("send_via_sms")}</span>
              </div>
              <span
                style={{ marginLeft: 25, marginRight: 25, color: "#7A7A7A" }}
              >
                {phoneNumber}
              </span>
            </div>

            <div className="line-separator" />

            <div
              onClick={() => onSelectHandler("Whatsapp")}
              className="d-flex align-items-center"
              style={{
                marginBottom: "35px",
                marginTop: "35px",
                cursor: "pointer",
              }}
            >
              <WhatsappIconSvg />
              <span className="otp-method-txt">{t("send_via_whatsapp")}</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectOtpMethod;
